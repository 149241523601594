import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { IGenericResponse } from '../interfaces/generic.interface';
import { ICustomer } from '../interfaces/customers.interface';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  apiUrl = `${environment.apiUrl}/company`;

  customers = signal<ICustomer[]>([]);
  customerActive = signal<ICustomer | null>(null);

  constructor(private http: HttpClient) {}

  getCustomers(): Observable<IGenericResponse<ICustomer[]>> {
    return this.http.get<IGenericResponse<ICustomer[]>>(this.apiUrl);
  }

  createCustomer(
    customer: ICustomer
  ): Observable<IGenericResponse<ICustomer[]>> {
    return this.http.post<IGenericResponse<ICustomer[]>>(this.apiUrl, customer);
  }

  updateCustomer(customer: ICustomer): Observable<IGenericResponse<ICustomer>> {
    return this.http.put<IGenericResponse<ICustomer>>(this.apiUrl, customer);
  }

  deleteCustomer(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }
}
